<template>
  <div>
    <v-data-table
      :headers="headersEstoque"
      :items="itensEstoque"
      :loading="lodingEstoque"
      :search="searchEstoque"
      fixed-header
      height="75vh"
      no-data-text="Pesquise um produto"
      hide-default-footer
      :items-per-page="itensEstoque.length"
      dense
      class="ma-5"
    >
      <template v-slot:[`item.DISPONIVEL`]="{ item }">
        <v-chip color="green darken-4" dark x-small>{{
          item.DISPONIVEL
        }}</v-chip>
      </template>

      <template v-slot:top>
        <div class="d-flex justify-space-around align-center">
          <div style="width: 25vw;" class="d-flex flex-column align-center">
            <v-radio-group v-model="tipoPesquisa" row>
              <v-radio label="Descrição" value="D"></v-radio>
              <v-radio label="Cód. Produto" value="P"></v-radio>
              <v-radio label="Cód. Barras" value="B"></v-radio>
            </v-radio-group>
            <v-text-field
              v-model="pesquisa"
              dense
              outlined
              label="Pesquisa"
              placeholder="Pesquisar por..."
              hide-details
              style="width: 40vw;"
              v-on:keyup.enter="buscaProduto"
            ></v-text-field>

            <v-btn
              @click="buscaProduto"
              class="primary"
              x-small
              style="margin-top: 10px;"
              >Buscar</v-btn
            >
          </div>
          <div style="width: 25vw;">
            <v-text-field
              v-model="searchEstoque"
              dense
              outlined
              label="Filtar resultado"
              hide-details
              class="mb-5"
            ></v-text-field>
            <span style="font-size: .7rem;">{{ dadosPesquisa }}</span>
          </div>
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapState } from "vuex";
import axios from "axios";
import format from "date-fns/format";
import { ptBR } from "date-fns/locale";
export default {
  name: "Estoque",
  computed: {
    ...mapState(["user"]),
  },
  data: () => ({
    nome: "Disponibilidade de Estoque",
    tipoPesquisa: "D",
    headersEstoque: [
      {
        text: "Produto",
        value: "DESCRICAO",
        align: "start",
      },
      {
        text: "Loja",
        value: "FILIAL",
        align: "center",
      },
      {
        text: "Local de estoque",
        value: "LOCAL",
        align: "center",
      },
      {
        text: "Disponibilidade ?",
        value: "DISPONIVEL",
        align: "center",
      },
    ],
    itensEstoque: [],
    lodingEstoque: false,
    searchEstoque: null,
    pesquisa: null,
    dadosPesquisa: null,
  }),
  methods: {
    async buscaProduto() {
      this.itensEstoque = [];
      this.dadosPesquisa = null;
      if (!this.pesquisa) return;
      this.lodingEstoque = true;
      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      const dados = {
        FIELDS:
          "B2_FILIAL+'-'+M0_FILIAL FILIAL,B2_LOCAL+'-'+NNR_DESCRI LOCAL,B1_COD+'-'+B1_DESC DESCRICAO,'SIM' DISPONIVEL",
        WHERE: ``,
        INNER: `INNER JOIN SB1010 SB1 WITH(NOLOCK) ON SB1.D_E_L_E_T_=' ' AND B1_COD=B2_COD AND B1_MSBLQL='2'

                INNER JOIN NNR010 NNR WITH(NOLOCK) ON NNR.D_E_L_E_T_=' ' AND NNR_CODIGO=B2_LOCAL

                INNER JOIN SYS_COMPANY LOJA WITH(NOLOCK) ON M0_CODFIL=B2_FILIAL `,
        TABELA: "SB2",
      };

      const digitado = this.pesquisa.toUpperCase();

      if (this.tipoPesquisa === "D") {
        dados.WHERE = `B1_DESC LIKE '%${digitado}%' AND (B2_QATU-(B2_RESERVA+B2_QPEDVEN))>0`;
      }
      if (this.tipoPesquisa === "P") {
        dados.WHERE = `B2_COD='${digitado}' AND (B2_QATU-(B2_RESERVA+B2_QPEDVEN))>0`;
      }
      if (this.tipoPesquisa === "B") {
        dados.WHERE = `B1_CODBAR='${digitado}' AND (B2_QATU-(B2_RESERVA+B2_QPEDVEN))>0`;
      }

      const result = await axios.post(url, dados);

      this.itensEstoque = result.data;

      this.dadosPesquisa = format(
        new Date(),
        "EEEE dd 'de' MMMM 'de' yyyy 'às' HH:mm",
        {
          locale: ptBR,
        },
      );

      this.lodingEstoque = false;
    },
  },
};
</script>
<style></style>
