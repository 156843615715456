import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDataTable,{staticClass:"ma-5",attrs:{"headers":_vm.headersEstoque,"items":_vm.itensEstoque,"loading":_vm.lodingEstoque,"search":_vm.searchEstoque,"fixed-header":"","height":"75vh","no-data-text":"Pesquise um produto","hide-default-footer":"","items-per-page":_vm.itensEstoque.length,"dense":""},scopedSlots:_vm._u([{key:"item.DISPONIVEL",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"color":"green darken-4","dark":"","x-small":""}},[_vm._v(_vm._s(item.DISPONIVEL))])]}},{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-around align-center"},[_c('div',{staticClass:"d-flex flex-column align-center",staticStyle:{"width":"25vw"}},[_c(VRadioGroup,{attrs:{"row":""},model:{value:(_vm.tipoPesquisa),callback:function ($$v) {_vm.tipoPesquisa=$$v},expression:"tipoPesquisa"}},[_c(VRadio,{attrs:{"label":"Descrição","value":"D"}}),_c(VRadio,{attrs:{"label":"Cód. Produto","value":"P"}}),_c(VRadio,{attrs:{"label":"Cód. Barras","value":"B"}})],1),_c(VTextField,{staticStyle:{"width":"40vw"},attrs:{"dense":"","outlined":"","label":"Pesquisa","placeholder":"Pesquisar por...","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.buscaProduto.apply(null, arguments)}},model:{value:(_vm.pesquisa),callback:function ($$v) {_vm.pesquisa=$$v},expression:"pesquisa"}}),_c(VBtn,{staticClass:"primary",staticStyle:{"margin-top":"10px"},attrs:{"x-small":""},on:{"click":_vm.buscaProduto}},[_vm._v("Buscar")])],1),_c('div',{staticStyle:{"width":"25vw"}},[_c(VTextField,{staticClass:"mb-5",attrs:{"dense":"","outlined":"","label":"Filtar resultado","hide-details":""},model:{value:(_vm.searchEstoque),callback:function ($$v) {_vm.searchEstoque=$$v},expression:"searchEstoque"}}),_c('span',{staticStyle:{"font-size":".7rem"}},[_vm._v(_vm._s(_vm.dadosPesquisa))])],1)])]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }